import { BaseDirective } from '@agdir/core/angular';
import { AfterContentInit, Directive, Host, input, output, ViewContainerRef } from '@angular/core';
import { ControlComponent, MapService } from 'ngx-mapbox-gl';
import { takeUntil } from 'rxjs';
import { MapboxCustomControlWrapper } from '../mapbox-custom-control-wrapper';
import { MapboxCheckboxComponent } from './zone-filter.component';

@Directive({
	selector: '[agdirCheckboxControl]',
	standalone: false,
})
export class CheckboxControlDirective extends BaseDirective implements AfterContentInit {
	label = input('N/A');

	showZone = output<boolean>();

	constructor(
		private viewContainerRef: ViewContainerRef,
		private mapService: MapService,
		@Host() private controlComponent: ControlComponent<MapboxCustomControlWrapper>,
	) {
		super();
	}

	ngAfterContentInit(): void {
		this.mapService.mapCreated$.subscribe(() => {
			const componentRef = this.viewContainerRef.createComponent(MapboxCheckboxComponent);

			componentRef.instance.label = this.label();
			componentRef.instance.onTogglebuttonClick.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
				this.showZone.emit(val);
			});
			componentRef.changeDetectorRef.detectChanges();

			this.controlComponent.control = new MapboxCustomControlWrapper(componentRef.location.nativeElement);

			this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
		});
	}
}
