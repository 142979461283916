import { Component, computed, model } from '@angular/core';

import { AgdirIconComponent } from '@agdir/agdir-icons';
import { Point } from 'geojson';
import { EventData, MapboxEvent } from 'mapbox-gl';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@Component({
	selector: 'agdir-map-center-pin-point',
	imports: [NgxMapboxGLModule, AgdirIconComponent],
	template: `
		<mgl-map
			[style]="'mapbox://styles/mapbox/streets-v11'"
			[zoom]="[zoomSignal()]"
			[scrollZoom]="{}"
			[center]="[point()?.coordinates?.[0], point()?.coordinates[1]]"
			(moveEnd)="onMapMove($event)"
			[fitBoundsOptions]="{ padding: 20 }"
			class="absolute inset-0"
		></mgl-map>
		<div class="absolute inset-0 flex justify-center items-center pointer-events-none text-red-500">
			<agdir-icon class="w-10 h-10" icon="my_location" />
		</div>
	`,
	styles: [
		`
			:host {
				@apply block relative w-full h-full;
			}
		`,
	],
})
export class MapCenterPinPointComponent {
	point = model<Point | null>(this.makePoint(10.4515, 51.1657));
	zoomSignal = computed<number>(() => (this.point() ? 12 : 4));

	onMapMove(event: MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & EventData) {
		const mapCenter = event.target.getCenter();
		this.point.set(this.makePoint(mapCenter.lng, mapCenter.lat));
	}

	private makePoint(x: number, y: number): Point {
		return { type: 'Point', coordinates: [x, y] };
	}
}
