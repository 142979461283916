import { Component, input, model } from '@angular/core';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzFormControlComponent, NzFormItemComponent, NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';

@Component({
	selector: 'agdir-people-multiselect',
	template: `
		<nz-form-item
			[class.flex-row]="horizontal() === true || horizontal() === 'true'"
			[class.items-center]="horizontal() === true || horizontal() === 'true'"
			[class.flex-col]="!horizontal() || horizontal() === 'false'"
			class="flex gap-2 justify-start"
		>
			@if (label() && size() == 'normal') {
				<nz-form-label class="font-bold p-0">{{ label() | transloco }}</nz-form-label>
			}
			@if (label() && size() == 'large') {
				<div class="text-3xl md:text-4xl font-bold">{{ label() | transloco }}</div>
			}
			@if (label() && size() == 'medium') {
				<div class="text-xl font-light">{{ label() | transloco }}</div>
			}
			@if (description() && size() == 'large') {
				<div class="text-xl flex-1 font-light">{{ description() | transloco }}</div>
			}
			<nz-form-control>
				<nz-select
					nzMode="multiple"
					nzShowSearch
					nzAllowClear
					nzPlaceHolder="{{ placeholder() | transloco }}"
					[ngModel]="people()"
					(ngModelChange)="people.set($event)"
				>
					@for (person of allPeople(); track $index) {
						<nz-option nzLabel="{{ person.name }} {{ person.surname }}" [nzValue]="person"></nz-option>
					}
				</nz-select>
			</nz-form-control>
		</nz-form-item>
	`,
	imports: [
		NzColDirective,
		NzFormControlComponent,
		NzFormItemComponent,
		NzFormLabelComponent,
		NzOptionComponent,
		NzRowDirective,
		NzSelectComponent,
		TranslocoPipe,
		FormsModule,
	],
})
export class PeopleMultiselectComponent {
	allPeople = model<Partial<{ name: string; surname: string; id: number }>[]>([]);
	people = model<Partial<{ name: string; surname: string; id: number }>[]>([]);
	label = input<string>();
	translate = input<'false' | 'true' | boolean>(true);
	size = input<'normal' | 'large' | 'medium'>('normal');
	description = input<string>('');
	horizontal = input<'false' | 'true' | boolean>();
	placeholder = input<string>();
}
