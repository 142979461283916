import { Component, input, output } from '@angular/core';
import { MultiPolygon } from 'geojson';
import { AnyPaint, MapMouseEvent, MapTouchEvent } from 'mapbox-gl';

// this is just proxy component for polygon projection from agdir-map -> ngx-mapbox-gl
@Component({
	standalone: true,
	selector: 'agdir-map-polygon',
	template: '<ng-content></ng-content>',
})
export class MapPolygonComponent {
	name = input<string>();
	feature = input<MultiPolygon>();
	deletable = input(true);
	fillPaint = input<AnyPaint>();
	outlinePaint = input<AnyPaint>();
	symbolPaint = input<AnyPaint>();
	polygonClick = output<MapMouseEvent | MapTouchEvent>();
}
