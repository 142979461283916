import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ButtonComponent } from '@agdir/ui/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-formly-field-button',
	template: `
		<agdir-button
			[isProcessing]="processing()"
			[nz-tooltip]="props['tooltip'] | transloco"
			[icon]="props['icon']"
			[color]="props['color']"
			(click)="onClick()"
			[ngClass]="props['class']"
			[type]="$any(props['type']) || 'button'"
			[label]="props['label']"
		/>
	`,
	imports: [NgClass, ButtonComponent, NzToolTipModule, TranslocoPipe],
})
export class AgdirFormlyButtonComponent extends FieldType<FieldTypeConfig> {
	processing = signal(false);

	async onClick() {
		this.processing.set(true);
		await this.props['onClick']?.(this.model, this.field);
		this.processing.set(false);
	}
}
