import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { AgdirSelectComponent } from '../agdir-select.component';


import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-simple-select',
	template: `
		<nz-form-item>
			<agdir-select
				[title]="$any(props['placeholder'])"
				[options]="$any(props['options'])"
				[selectControl]="formControl"
				[horizontal]="!!props['horizontal']"
				[placeholder]="props['placeholder']"
				[description]="props['description']"
				[label]="props['label']"
				[size]="props['size'] || 'normal'"
			/>
		</nz-form-item>
	`,
	styles: [
		`
			:host {
				@apply flex items-center justify-start;
			}
		`,
	],
	imports: [MatInputModule, MatSelectModule, ReactiveFormsModule, AgdirSelectComponent, NzFormModule, NzGridModule],
})
export class SimpleSelectComponent extends FieldType<FieldTypeConfig> {}
