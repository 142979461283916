import { BaseDirective } from '@agdir/core/angular';
import { AfterContentInit, ComponentRef, Directive, Host, NgZone, output, ViewContainerRef } from '@angular/core';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { bbox, union } from '@turf/turf';
import { ControlComponent, MapService } from 'ngx-mapbox-gl';
import { debounceTime, fromEvent, takeUntil } from 'rxjs';
import { LocationMergeResult } from '../../../utils';
import { AgdirMapComponent } from '../../map.component';
import { MapboxCustomControlWrapper } from '../mapbox-custom-control-wrapper';
import { MapboxMergeControlComponent } from './mapbox-merge-control.component';

@Directive({
	selector: '[agdirMerge]',
	standalone: false,
})
export class MapboxMergeControlDirective extends BaseDirective implements AfterContentInit {
	toggleDraw = output<boolean>();
	locationMerged = output<LocationMergeResult>();

	drawEnabled = false;
	drawControlComponentRef?: ComponentRef<MapboxMergeControlComponent>;

	constructor(
		private viewContainerRef: ViewContainerRef,
		private mapService: MapService,
		private agdirMapCmp: AgdirMapComponent,
		@Host() private controlComponent: ControlComponent<MapboxCustomControlWrapper>,
		private zone: NgZone,
	) {
		super();
	}

	ngAfterContentInit(): void {
		this.mapService.mapCreated$.subscribe(() => {
			this.drawControlComponentRef = this.createRef();

			this.controlComponent.control = new MapboxCustomControlWrapper(this.drawControlComponentRef.location.nativeElement);
			this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);

			fromEvent(this.mapService.mapInstance, 'draw.create')
				.pipe(takeUntil(this.destroyed$), debounceTime(100))
				.subscribe((geometry) => this.onMapBoxDraw(geometry));
		});
	}

	toggleDrawMode() {
		this.drawEnabled = !this.drawEnabled;
		this.agdirMapCmp.drawingMode.set(this.drawEnabled);

		if (this.drawEnabled) {
			this.getMapDrawInstance().changeMode('draw_polygon');
		} else {
			this.getMapDrawInstance()?.trash();
		}

		if (this.drawControlComponentRef) {
			this.drawControlComponentRef.instance.drawEnabled = this.drawEnabled;
			this.drawControlComponentRef.changeDetectorRef.detectChanges();
		}

		this.toggleDraw.emit(this.drawEnabled);
	}

	private onMapBoxDraw(event: MapboxDraw.DrawCreateEvent) {
		const polygons = this.getPolygonsToMerge(event);

		this.zone.run(() => {
			this.locationMerged.emit({
				source: polygons.map((s) => s.layer.id.split('polygon-fill-').at(-1) as string),
				polygon: this.mergePolygons([...polygons, ...event.features]),
			});
		});
		this.toggleDrawMode();
	}

	private getPolygonsToMerge(event: MapboxDraw.DrawCreateEvent) {
		const boundingBox = bbox(event.features[0]);
		const northEastPointPixel = this.mapService.mapInstance.project([boundingBox[2], boundingBox[3]]);
		const southWestPointPixel = this.mapService.mapInstance.project([boundingBox[0], boundingBox[1]]);

		const renderedPolygons = this.mapService.mapInstance
			.queryRenderedFeatures([southWestPointPixel, northEastPointPixel])
			.filter((s) => s.layer.id.startsWith('polygon-fill-'));

		return [...new Map(renderedPolygons.map((item) => [item.layer.id, item])).values()];
	}

	private mergePolygons(polygons: any[]) {
		let unioned = polygons[0];
		for (let i = 1; i < polygons.length; i++) {
			unioned = union(unioned, polygons[i]);
		}
		return unioned;
	}

	private createRef() {
		const ref = this.viewContainerRef.createComponent(MapboxMergeControlComponent);
		ref.instance.drawEnabled = this.drawEnabled;

		ref.instance.onMergeButtonClick.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.toggleDrawMode();
		});

		return ref;
	}

	private getMapDrawInstance() {
		const mapboxDrawInstance: MapboxDraw = (this.mapService.mapInstance as any)._controls.find((s: any) => !!s.changeMode);

		if (mapboxDrawInstance) {
			return mapboxDrawInstance;
		}

		const mapDraw = new MapboxDraw({
			touchEnabled: true,
			displayControlsDefault: false,
		});

		this.mapService.mapInstance.addControl(mapDraw);

		return mapDraw;
	}
}
