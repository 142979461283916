import { JsonCircularDepFixInterceptor } from '@agdir/services';
import { EnvironmentProviders, ErrorHandler, Provider, inject, provideAppInitializer } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { SentryWrapperService } from './sentry-wrapper.service';
import { WindowWithAgdirLatestError } from './window-with-agdir-latest.error';

export const provideSentryLoggers = (): Array<Provider | EnvironmentProviders> => {
	return [
		SentryWrapperService.forRoot(),
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
				extractor: (
					extractableError: unknown,
					defaultExtractor: (error: unknown) => Error & {
						zoneAwareStack?: Error;
					},
				) => {
					// Use the default extractor to get the error object
					const error: Error & { zoneAwareStack?: Error } = defaultExtractor(extractableError);

					if (!error) {
						console.error('Undefined client error');
						// Return null or undefined to prevent the error from being sent to Sentry
						return null;
					}
					// Check if it's an error from an HTTP response
					//  if (!(e instanceof HttpErrorResponse)) {
					//  	error = e.rejection; // get the error object
					// }

					// skip NG0100: Expression has changed after it was checked
					if (error.message?.includes('NG0100')) {
						// Return null or undefined to prevent the error from being sent to Sentry
						return null;
					}
					// skip 504: After waking up from idle, 504 GatewatTimeout is received from backend.
					if (error.message?.includes('504')) {
						// Return null or undefined to prevent the error from being sent to Sentry
						return null;
					}
					(<WindowWithAgdirLatestError>window).agdirLatestError = JSON.stringify(JsonCircularDepFixInterceptor.decirculize(error), null, 2);

					return error;
				},
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		provideAppInitializer(() => {
			inject(Sentry.TraceService);
		}),
	];
};
