import { Field } from '@agdir/domain';
import { I18nService } from '@agdir/i18n/angular';
import { FieldService } from '@agdir/services';
import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { firstValueFrom, map, Observable, startWith } from 'rxjs';

type SelectedValue = string | string[] | null;

@Component({
	selector: 'agdir-formly-location',
	template: `
		<mat-form-field class="w-full">
			<mat-label>{{ props.label }}</mat-label>
			<mat-select
				[placeholder]="props.placeholder!"
				[value]="selectedValue$ | async"
				(selectionChange)="onSelectionChange($event)"
				[multiple]="props['multi']"
			>
				@for (location of locations$ | async; track location) {
					<mat-option [value]="location.id">
						{{ location.name }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>
	`,
	imports: [MatFormFieldModule, MatSelectModule, AsyncPipe],
})
export class LocationFieldTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
	locations$?: Observable<Field[]>;
	selectedValue$?: Observable<SelectedValue>;

	constructor(
		private locationService: FieldService,
		private translate: I18nService,
	) {
		super();
	}

	get isMulti() {
		return this.props['multi'];
	}

	ngOnInit() {
		this.locations$ = (
			this.props?.['locationType']
				? this.locationService.getLocationsByType(this.props['locationType'])
				: this.locationService.getAllLocations()
		).pipe(
			map((s) => {
				if (!this.props['emptyOption']) {
					return s;
				}
				return [
					{
						_id: null,
						name: this.translate.translate('modules.locationPage.editLocationDevice.unassignedLocation'),
					} as any,
					...s,
				];
			}),
		);

		this.selectedValue$ = this.formControl.valueChanges.pipe(
			startWith(this.formControl.value),
			map((s) => this.mapId(s)),
		);
	}

	async onSelectionChange(ev: MatSelectChange) {
		if (!this.locations$) {
			return;
		}
		const selected = await this.getSelected(ev);
		this.formControl.setValue(selected);
		this.formControl.markAsDirty();
	}

	private mapId(value: Field | Field[] | string | string[] | null): SelectedValue {
		if (!value) {
			return null;
		}

		if (this.props['valueAsId']) {
			return value as string | string[];
		}

		if (this.isMulti) {
			const mapped = (value as Field[]).map((s) => s.id as string);
			return mapped.length > 0 ? mapped : null;
		}
		return (value as Field).id as string;
	}

	private async getSelected(ev: MatSelectChange): Promise<Field | Field[] | string | string[] | null | undefined> {
		if (!this.locations$) {
			return null;
		}

		const locations = await firstValueFrom(this.locations$);
		if (this.isMulti) {
			const filtered = locations.filter((s) => ev.value.includes(s.id));
			if (!filtered) {
				return null;
			}
			if (this.props['valueAsId']) {
				return filtered.map((s) => s.id as string);
			}
			return filtered;
		}

		const location = locations.find((s) => ev.value === s.id);
		if (this.props['valueAsId']) {
			return location?.id;
		}
		return location;
	}
}
