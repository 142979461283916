import { AgdirSentryEnvironment, SentryWrapperService } from '@agdir/sentry';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (!environment.isLocal && !environment.isDev) {
	SentryWrapperService.init(environment.get(AgdirSentryEnvironment));
}

// trigger: 6
bootstrapApplication(AppComponent, appConfig).catch((e) => console.error(e));
