import { Component, EventEmitter } from '@angular/core';

@Component({
	selector: 'agdir-mapbox-draw-control',
	templateUrl: './mapbox-draw-control.component.html',
	styleUrl: './mapbox-draw-control.component.scss',
	standalone: false,
})
export class MapboxDrawControlComponent {
	onDrawBtnClick: EventEmitter<void> = new EventEmitter<void>();
	drawEnabled = false;

	drawBtnClick() {
		this.onDrawBtnClick.emit();
	}
}
