import { Component, EventEmitter } from '@angular/core';

@Component({
	selector: 'agdir-mapbox-merge-control',
	templateUrl: './mapbox-merge-control.component.html',
	styleUrl: './mapbox-merge-control.component.scss',
	standalone: false,
})
export class MapboxMergeControlComponent {
	onMergeButtonClick: EventEmitter<void> = new EventEmitter<void>();
	drawEnabled = false;

	mergeBtnClick() {
		this.onMergeButtonClick.emit();
	}
}
