import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter } from '@angular/core';

import { AgdirIconComponent } from '@agdir/agdir-icons';
import { MatRippleModule } from '@angular/material/core';

@Component({
	selector: 'agdir-zoning-layer-control',
	template: `
		<button type="button" matRipple (click)="click()" class="flex flex-col items-center justify-center w-full h-auto py-1 px-0.5">
			@if (zonesAreShown) {
				<agdir-icon class="w-5" icon="layers_clear" />
			}
			@if (!zonesAreShown) {
				<agdir-icon class="w-5" icon="layers" />
			}
			@if (zonesAreShown) {
				<span>Hide zoning layers</span>
			}
			@if (!zonesAreShown) {
				<span>Show zoning layers</span>
			}
		</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatRippleModule, AgdirIconComponent],
})
export class ZoningLayerControlComponent {
	zonesAreShown = false;
	clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private cd: ChangeDetectorRef) {}

	click() {
		this.zonesAreShown = !this.zonesAreShown;
		this.clicked.emit(this.zonesAreShown);
		this.cd.markForCheck();
		this.cd.detectChanges();
	}
}
