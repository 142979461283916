import { Component, computed, input, InputSignal, output, signal } from '@angular/core';

import { NzTableModule } from 'ng-zorro-antd/table';
import { AgdirDatatableEntity } from './agdir-datatable.entity';
import { convertTreeToList } from './datatable-convertor';
import { ButtonComponent } from '@agdir/ui/button';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { datatableFilterSearch } from './datatable-filter-search';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	selector: 'agdir-datatable',
	template: `
		<nz-dropdown-menu #menu="nzDropdownMenu">
			<div class="ant-table-filter-dropdown">
				<form class="w-40 flex flex-col p-2 gap-2" (submit)="search($event, nameSearchValue)">
					<input
						type="text"
						nz-input
						placeholder="{{ 'general.searchText' | transloco }}"
						name="nameSearchValue"
						[(ngModel)]="nameSearchValue"
						class="flex-1"
					/>
					<div class="flex flex-row items-center justify-between gap-2 flex-1">
						<agdir-button
							class="flex-1 "
							type="button"
							size="small"
							color="ghost"
							label="general.cancelText"
							(click)="search($event, '')"
						/>
						<agdir-button class="flex-1" type="submit" size="small" color="green" label="general.ok" />
					</div>
				</form>
			</div>
		</nz-dropdown-menu>
		<nz-table
			nzSize="small"
			#filterTable
			[nzData]="filteredSortedData()"
			[nzFrontPagination]="true"
			[nzPageSize]="20"
			[nzPageSizeOptions]="[10, 20, 30, 50, 100]"
		>
			<thead>
				<tr>
					<th nzWidth="30px"></th>
					@for (column of listOfColumns(); track $index) {
						<th
							nzCustomFilter
							[(nzSortOrder)]="column.sortOrder"
							[nzSortFn]="column.sortFn"
							[nzFilters]="column.listOfFilter"
							[nzFilterFn]="column.filterFn"
						>
							{{ column.name }}
							<nz-filter-trigger [(nzVisible)]="nameSearchVisible" [nzActive]="nameSearchValue.length > 0" [nzDropdownMenu]="menu">
								<agdir-icon icon="search" />
							</nz-filter-trigger>
						</th>
					}
				</tr>
			</thead>
			<tbody>
				@for (data of filterTable.data; track $index) {
					@for (item of mapOfExpandedData[data.id]; track $index) {
						@if ((item.parent && item.parent.expand) || !item.parent) {
							<tr (click)="rowClicked($event, item)">
								<!-- We (I) assume there will be either a checkbox or an expand icon here. If you need to split, just make two columns-->
								<td
									nzWidth="30px"
									[nzShowExpand]="!!item.children && item.children?.length > 0"
									[(nzExpand)]="item.expand"
									(nzExpandChange)="collapse(mapOfExpandedData[data.id], item, $event)"
									[nzShowCheckbox]="!item.children || !item.children?.length"
									[nzChecked]="selectedIds().has(item.id)"
									(nzCheckedChange)="checkChanged(item)"
								></td>
								<td
									class="hover-pointer"
									[nzIndentSize]="item.level! * 10"
									[class.font-bold]="!!item.children && item.children?.length > 0"
									(click)="iDontKnowABetterWayToAddressTheCheboxInPreviousCell($event)"
								>
									{{ item.name }}
								</td>
							</tr>
						}
					}
				}
			</tbody>
		</nz-table>
	`,
	imports: [NzTableModule, ButtonComponent, NzInputDirective, NzDropdownMenuComponent, TranslocoPipe, FormsModule, AgdirIconComponent],
})
export class AgdirDatatableComponent {
	mapOfExpandedData: { [key: string]: AgdirDatatableEntity[] } = {};

	/**
	 * Name column specific stuff. we will expand later ;)
	 */
	nameSearchValue = '';
	nameSearchQuery = signal<string>('');
	nameSearchVisible = false;
	nameLabel = input<string>('Name');

	data: InputSignal<AgdirDatatableEntity[]> = input.required<AgdirDatatableEntity[]>();
	toggleSelection = output<AgdirDatatableEntity>();
	selectionChanged = output<AgdirDatatableEntity[]>();

	selectedIds = computed(() => {
		const selectedItems = this.data() || [];
		const selectedIds = new Set<number>();
		selectedItems.forEach((item) => {
			if (item.selected) {
				selectedIds.add(item.id);
			}
			if (item.children) {
				item.children.forEach((child) => {
					if (child.selected) {
						selectedIds.add(child.id);
						item.expand = true;
					}
				});
			}
		});
		return selectedIds;
	});

	filteredSortedData = computed(() => {
		const items = this.data();
		const nameSearchQuery = this.nameSearchQuery().toLocaleLowerCase();
		const filtered = datatableFilterSearch(items, nameSearchQuery);
		filtered?.forEach((item) => (this.mapOfExpandedData[item.id] = convertTreeToList(item)));
		return filtered;
	});

	listOfColumns = computed(() => {
		return [
			{
				name: this.nameLabel(),
				sortOrder: 'ascend',
				sortFn: (a: AgdirDatatableEntity, b: AgdirDatatableEntity) => {
					return a.name.localeCompare(b.name);
				},
				listOfFilter: [] as { text: string; value: string }[],
				filterFn: (list: string[], item: AgdirDatatableEntity) => list.some((name) => item.name.includes(name)),
			},
		];
	});

	search($event: Event, query: string): void {
		$event.preventDefault();
		this.nameSearchQuery.set(query);
		this.nameSearchVisible = false;
	}

	collapse(array: AgdirDatatableEntity[], data: AgdirDatatableEntity, $event: boolean): void {
		if (!$event) {
			if (data.children) {
				data.children.forEach((d) => {
					const target = array.find((a) => a.id === d.id)!;
					target.expand = false;
					this.collapse(array, target, false);
				});
			} else {
				return;
			}
		}
	}

	checkChanged(item: AgdirDatatableEntity) {
		item.selected = !item.selected;
		this.toggleSelection.emit(item);
		this.selectionChanged.emit(this.data());
	}

	rowClicked($event: MouseEvent, item: AgdirDatatableEntity) {
		$event.stopPropagation();
		// this.checkChanged(item);
	}

	iDontKnowABetterWayToAddressTheCheboxInPreviousCell(e: Event) {
		e.preventDefault();
		const cell: HTMLTableCellElement = e.target as HTMLTableCellElement;
		const checkbox: HTMLInputElement | null = cell.parentElement?.querySelector('input[type=checkbox]') || null;
		checkbox?.click();
	}
}
