import { getCustomerLocationInformation } from '@agdir/core/angular';
import { SUPPORTED_COUNTRIES_SET } from '@agdir/domain';
import { ChangeDetectionStrategy, Component, effect, ElementRef, input, InputSignalWithTransform, model, signal, viewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import intlTelInput, { Iti } from 'intl-tel-input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputDirective, NzInputModule } from 'ng-zorro-antd/input';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-phone-number',
	template: `
		<input
			type="tel"
			class="w-full"
			nz-input
			name="phoneNumber"
			autocomplete="tel"
			#intlPhoneElement
			[disabled]="disabled()"
			(blur)="validateNumber()"
			(change)="validateNumber()"
			(input)="validateNumber()"
			(paste)="validateNumber()"
			[class.invalid]="isValid() === false"
		/>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
		`
			:host ::ng-deep .iti {
				display: block;
				@apply h-full;
			}
		`,
		`
			:host ::ng-deep [type='tel'].invalid {
				@apply border-red-500;
			}
		`,
	],
	imports: [NzFormModule, NzInputModule, NzInputDirective],
})
export class PhoneNumberComponent {
	intlPhoneElement = viewChild<ElementRef<HTMLInputElement>>('intlPhoneElement');
	iti!: Iti;
	isValid = signal<boolean | null>(null);
	disabled: InputSignalWithTransform<boolean, boolean | null> = input(false, { transform: (v: boolean | null) => !!v });
	control = input<AbstractControl<string, string>>();
	model = model<string>();

	constructor() {
		effect(() => {
			const intlPhoneElement = this.intlPhoneElement();
			if (intlPhoneElement) {
				if (!this.iti) {
					this.iti = intlTelInput(intlPhoneElement.nativeElement, {
						containerClass: '',
						separateDialCode: true,
						formatOnDisplay: true,
						geoIpLookup: function (callback) {
							getCustomerLocationInformation().then((data) => callback(data.country));
						},
						initialCountry: 'auto',
						nationalMode: true,
						onlyCountries: [...SUPPORTED_COUNTRIES_SET],
						utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js',
					});

					this.iti.promise.then((x) => {
						this.iti.setNumber(this.model() || '');
						this.control()?.setValue(this.iti.getNumber());
					});
				}
			}
		});
	}

	validateNumber(): void {
		const isValid = this.iti.isValidNumber();
		this.isValid.set(isValid);
		if (isValid) {
			this.control()?.setValue(this.iti.getNumber());
			this.model.set(this.iti.getNumber());
		} else {
			this.control()?.setValue('');
			this.model.set('');
		}
	}
}
