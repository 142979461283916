import { MapsModule } from '@agdir/maps';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { MultiPolygon } from 'geojson';
import { Point } from 'geojson';

@Component({
	selector: 'agdir-geo-location',
	template: `
		<agdir-map
			(newMarkerPosition)="setValue($event)"
			(locationUpdated)="setValue($event)"
			[devices]="props['devices'] || []"
			[locations]="props['locations'] || []"
			[boundToDevice]="props['boundToDevice']"
			[newMarker]="isPolygonValue ? null : formControl.value"
			[editMode]="props['editMode']"
			[setMarkerOnClick]="props['setMarkerOnClick'] ?? true"
			[showFullscreen]="props['showFullscreen'] ?? false"
			[showLocateMeControl]="true"
			class="h-72"
			[ngClass]="props['className']"
		></agdir-map>
	`,
	imports: [MapsModule, NgClass],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoLocationFieldTypeComponent extends FieldType<FieldTypeConfig> {
	get isPolygonValue() {
		return !!this.props['valueAsPolygon'];
	}

	setValue(value: Point | MultiPolygon | undefined) {
		this.formControl.setValue(value);
		this.formControl.markAsDirty();
	}
}
