import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NzFormModule } from 'ng-zorro-antd/form';

import { ClassifierSelectComponent } from '../components/classifier-select.component';

import { Classifier } from '@agdir/classifiers';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-equipment-manufacturer-select-formly',
	template: `
		@if (formControl) {
			@if (props['multi']) {
				not implemented yet
			} @else {
				<agdir-classifier-select
					[classifier]="dontRecurseOk"
					(classifierChange)="setFlatClassifier($event)"
					[knownClassifierId]="props['knownClassifierCode']"
					[label]="props['label']"
					[description]="props['description']"
					[size]="props['size']"
				/>
			}
		}
	`,
	imports: [ReactiveFormsModule, NzFormModule, ClassifierSelectComponent],
})
export class AgdirFormlyClassifierSelect extends FieldType<FieldTypeConfig> implements OnInit {
	dontRecurseOk!: Classifier;

	ngOnInit() {
		this.dontRecurseOk = this.field.formControl.value;
	}

	setFlatClassifier(classifier: Classifier | undefined) {
		this.field.formControl.setValue(classifier?.toDto());
	}
}
