import { Profile } from '@agdir/domain';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { AgdirSentryEnvironment } from './agdir-sentry-environment';

@Injectable()
export class SentryWrapperService {
	static init(e: AgdirSentryEnvironment) {
		Sentry.init(this.asSentryOptions(e));
	}

	static forRoot() {
		return {
			provide: SentryWrapperService,
			useClass: SentryWrapperService,
		};
	}

	setUserContext(customer: Profile): void {
		Sentry.setUser({
			id: customer.id!,
			phone: customer.phoneNumber,
			email: customer.email,
			name: customer.name,
			surname: customer.surname,
		});
	}

	static asSentryOptions(e: AgdirSentryEnvironment): Sentry.BrowserOptions {
		return {
			dsn: e.dsn,
			integrations: e.integrations,
			tracesSampleRate: e.tracesSampleRate,
			tracePropagationTargets: e.tracePropagationTargets,
			release: e.release,
			replaysSessionSampleRate: e.replaysSessionSampleRate,
			replaysOnErrorSampleRate: e.replaysOnErrorSampleRate,
		};
	}
}
