import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldArrayType, FieldTypeConfig } from '@ngx-formly/core';
import { AttachmentsComponent } from '../components/attachments.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-formly-attachments',
	template: `
		<agdir-attachments
			[control]="formControl"
			[label]="props.label"
			[size]="props.size"
			[folder]="props.folder"
			[description]="props.description"
		/>
	`,
	imports: [AttachmentsComponent],
})
export class FormlyAttachmentsComponent extends FieldArrayType<FieldTypeConfig> {}
