import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { DatepickerComponent } from '../components/datepicker.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-formly-datepicker',
	template: `
		<agdir-datepicker
			[horizontal]="props['horizontal']"
			[size]="props['size'] || 'default'"
			[date]="formControl"
			[controlType]="props['controlType']"
			[showTimeSelection]="props['showTimeSelection']"
			[showTomorrowTodayTomorrowShortcuts]="props['showTomorrowTodayTomorrowShortcuts']"
			[placeholder]="props['placeholder']"
			[description]="props['description']"
			[labelName]="props['label']"
			[editDisabled]="props['editDisabled'] || props['disabled']"
			[maxDate]="props['maxDate']"
			[appearance]="props['appearance'] || 'outline'"
		></agdir-datepicker>
	`,
	imports: [FormlyModule, ReactiveFormsModule, DatepickerComponent],
})
export class AgdirDatepickerComponent extends FieldType<FieldTypeConfig> {}
