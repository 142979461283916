import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { PhoneNumberComponent } from '../components/agdir-phone-number/agdir-phone-number.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-phone-number-formly',
	template: `
		<nz-form-item>
			@if (props.label) {
				<nz-form-label nzFor="phoneNumber">{{ props.label }}</nz-form-label>
			}
			<agdir-phone-number [control]="formControl" />
		</nz-form-item>
	`,
	imports: [NzFormModule, PhoneNumberComponent],
})
export class PhoneNumberFormlyComponent extends FieldType<FieldTypeConfig> {}
