import { ChangeDetectionStrategy, Component, computed, inject, input, signal, WritableSignal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { Classifier, ClassifierService, WellKnownClassifierId } from '@agdir/classifiers';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';

import { I18nService } from '@agdir/i18n/angular';
import { BadgeComponent } from '@agdir/ui/badge';
import { TranslocoPipe } from '@ngneat/transloco';
import { computedAsync } from 'ngxtension/computed-async';
import { map } from 'rxjs';
import { ClassifierSelectComponent } from './classifier-select.component';


@Component({
	selector: 'agdir-crop-type-multi-select',
	template: `
		<nz-form-label>{{ 'cropTypeSelector.title' | transloco }}</nz-form-label>
		<agdir-classifier-select
			[(classifier)]="selectedCrop"
			(classifierChange)="addCropToList($event)"
			[knownClassifierId]="WellKnownClassifierId.CropTypes"
			(clear)="selectedCrops().set([])"
		/>
		<div class="text-sm">{{ 'cropTypeSelector.description' | transloco }}</div>

		<div class="flex flex-wrap gap-1 my-2">
			@for (crop of selectedCrops()(); track $index) {
				<agdir-badge icon="close" label="{{ crop.breadcrumb }}" (click)="removeFromList(crop)" class="cursor-pointer" />
			}
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
			:host {
				@apply flex flex-col items-start;
			}
		`,
	],
	imports: [
    NzSelectModule,
    ReactiveFormsModule,
    NzFormModule,
    NzGridModule,
    BadgeComponent,
    TranslocoPipe,
    ClassifierSelectComponent
],
})
export class CropTypeMultiSelectComponent {
	selectedCrop = signal<Classifier | null>(null);
	selected = input.required<WritableSignal<Classifier[]>>();
	selectedCrops = computed(() => this.selected());
	private readonly classifierService = inject(ClassifierService);
	private readonly translate = inject(I18nService);
	crops = computedAsync<Classifier[]>(
		() => this.classifierService.getClassifierById(WellKnownClassifierId.CropTypes).pipe(map((c) => c.children || [])),
		{ initialValue: [] },
	);

	async addCropToList(classifier: Classifier, { emit }: { emit: boolean } = { emit: true }) {
		if (classifier) {
			this.selectedCrops().update((selectedCrops) => [...(selectedCrops || []), classifier]);
		}
	}

	async addCropByCode(code: string, { emit }: { emit: boolean } = { emit: true }) {
		if (this.selectedCrops()()?.some((crop) => crop.code === code)) {
			return;
		}
		const classifier = await this.classifierService.getClassifierByCodeAsync(code);
		await this.addCropToList(classifier);
	}

	removeFromList(classifier: Classifier) {
		this.selectedCrops().update((selectedCrops) => (selectedCrops || []).filter((crop) => crop.id !== classifier.id));
	}

	protected readonly WellKnownClassifierId = WellKnownClassifierId;
}
