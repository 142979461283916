(window as any).global = window;
(window as any).process = {
	env: { DEBUG: undefined },
};

/**
 * @see https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
 */
if (!('toJSON' in Error.prototype))
	Object.defineProperty(Error.prototype, 'toJSON', {
		value: function () {
			return Object.getOwnPropertyNames(this).reduce(
				(alt, key) => {
					alt[key] = this[key];
					return alt;
				},
				{} as Record<string, any>,
			);
		},
		configurable: true,
		writable: true,
	});
