import { BaseDirective } from '@agdir/core/angular';
import { AfterContentInit, ComponentRef, Directive, Host, NgZone, output, ViewContainerRef } from '@angular/core';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
	booleanDisjoint,
	difference,
	feature,
	featureCollection,
	lineIntersect,
	lineOffset,
	lineString,
	lineToPolygon,
	multiPolygon,
} from '@turf/turf';
import { Feature, LineString, MultiPolygon, Polygon } from 'geojson';
import { ControlComponent, MapService } from 'ngx-mapbox-gl';
import { debounceTime, filter, fromEvent, map, takeUntil } from 'rxjs';
import { LocationSplitResult } from '../../../utils';
import { AgdirMapComponent } from '../../map.component';
import { MapboxCustomControlWrapper } from '../mapbox-custom-control-wrapper';
import { MapboxSplitControlComponent } from './mapbox-split-control.component';

@Directive({
	selector: '[agdirSplit]',
	standalone: false,
})
export class MapboxSplitControlDirective extends BaseDirective implements AfterContentInit {
	toggleDraw = output<boolean>();
	locationSplitted = output<LocationSplitResult>();

	drawEnabled = false;
	drawControlComponentRef?: ComponentRef<MapboxSplitControlComponent>;

	private selectedField?: MultiPolygon | null;

	constructor(
		private viewContainerRef: ViewContainerRef,
		private mapService: MapService,
		private zone: NgZone,
		private agdirMapCmp: AgdirMapComponent,
		@Host() private controlComponent: ControlComponent<MapboxCustomControlWrapper>,
	) {
		super();
	}

	ngAfterContentInit(): void {
		this.agdirMapCmp.objectClick.subscribe(([, feature]) => {
			if (this.selectedField) {
				return;
			}
			this.selectedField = feature;

			if (this.selectedField && this.drawEnabled) {
				this.getMapDrawInstance()?.changeMode('draw_line_string');
			}
		});

		this.mapService.mapCreated$.subscribe(() => {
			fromEvent(this.mapService.mapInstance, 'draw.create')
				.pipe(
					takeUntil(this.destroyed$),
					debounceTime(100),
					map((e) => e.features[0].geometry as LineString),
					filter((s) => s.type === 'LineString'),
				)
				.subscribe((geometry) => {
					if (this.selectedField) {
						const result = polygonCut(this.selectedField, geometry) as MultiPolygon;
						if (result) {
							this.getMapDrawInstance()?.trash();
							this.onMapBoxDraw({
								source: (this.selectedField as any).source.split('polygon-')[1],
								locationPolygons: [result], // result.coordinates.map((s) => multiPolygon(s)),
							});
						}
					}
				});

			this.drawControlComponentRef = this.createRef();
			this.controlComponent.control = new MapboxCustomControlWrapper(this.drawControlComponentRef.location.nativeElement);
			this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
		});
	}

	onMapBoxDraw(event: LocationSplitResult) {
		this.zone.run(() => {
			this.locationSplitted.emit(event);
			this.toggleDrawMode();
		});
	}

	toggleDrawMode() {
		this.drawEnabled = !this.drawEnabled;
		this.agdirMapCmp.fieldSelectionMode.set(this.drawEnabled);
		this.agdirMapCmp.drawingMode.set(this.drawEnabled);

		if (this.drawControlComponentRef) {
			this.drawControlComponentRef.instance.drawEnabled = this.drawEnabled;
			this.drawControlComponentRef.changeDetectorRef.detectChanges();
		}

		if (!this.drawEnabled) {
			this.selectedField = null;
			this.getMapDrawInstance()?.trash();
		}

		this.toggleDraw.emit(this.drawEnabled);
	}

	private createRef() {
		const ref = this.viewContainerRef.createComponent(MapboxSplitControlComponent);

		ref.instance.onSplitButtonClick.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.toggleDrawMode();
		});

		return ref;
	}

	private getMapDrawInstance() {
		const mapboxDrawInstance: MapboxDraw = (this.mapService.mapInstance as any)._controls.find((s: any) => !!s.changeMode);

		if (mapboxDrawInstance) {
			return mapboxDrawInstance;
		}

		const mapDraw = new MapboxDraw({
			touchEnabled: true,
			displayControlsDefault: false,
		});

		this.mapService.mapInstance.addControl(mapDraw);

		return mapDraw;
	}
}

function polygonCut(poly: MultiPolygon, line: LineString): MultiPolygon | null {
	const THICK_LINE_UNITS = 'kilometers';
	const THICK_LINE_WIDTH = 0.001;
	let i, j, forCut;
	let thickLineString, thickLinePolygon;
	let polyCoords = [];
	const offsetLine = [];
	let clipped: any;

	if (poly.type != 'MultiPolygon' || line.type != 'LineString') {
		return null;
	}

	const intersectPoints = lineIntersect(poly, line);
	if (intersectPoints.features.length == 0) {
		return null;
	}

	if (booleanDisjoint(line, poly)) {
		return null;
	}

	offsetLine[0] = lineOffset(line, THICK_LINE_WIDTH, {
		units: THICK_LINE_UNITS,
	});
	offsetLine[1] = lineOffset(line, -THICK_LINE_WIDTH, {
		units: THICK_LINE_UNITS,
	});

	for (i = 0; i <= 1; i++) {
		forCut = i;
		polyCoords = [];
		for (j = 0; j < line.coordinates.length; j++) {
			polyCoords.push(line.coordinates[j]);
		}
		for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
			polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
		}
		polyCoords.push(line.coordinates[0]);

		thickLineString = lineString(polyCoords);
		const pol = lineToPolygon(thickLineString) as Feature<Polygon>;
		thickLinePolygon = multiPolygon([pol.geometry.coordinates]);
		clipped = difference(featureCollection([feature(poly), thickLinePolygon])) as Feature<MultiPolygon>;
	}
	return clipped?.geometry;
}
