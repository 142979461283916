import { CompanyAssetsService } from '@agdir/services';
import { Equipment, EquipmentVendor, JohnDeereMachine, JohnDeereMapper, VendorName } from '@agdir/vogn/domain';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable, ReplaySubject, switchMap } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VognService {
	private http = inject(CompanyAssetsService);
	API_ENDPOINT = '/{companyId}/';

	cache$ = new Map<'integrations' | 'equipment', ReplaySubject<any>>([]);

	async reload(): Promise<void> {
		this.cache$.get('equipment')?.next(await firstValueFrom(this.fetchEquipment()));
	}

	getOrganizations() {
		return this.http.get<{ values: any[] }>(`${this.API_ENDPOINT}vendors/{companyId}/organizations`);
	}

	getEquipmentAsync(): Promise<Equipment[]> {
		return firstValueFrom(this.getEquipment());
	}
	getEquipment(): Observable<Equipment[]> {
		return this.fetchEquipment();

		// if (this.cache$.has('equipment')) {
		// 	return this.cache$.get('equipment')?.asObservable() as Observable<Equipment[]>;
		// }
		// this.cache$.set('equipment', new ReplaySubject<Equipment[]>(1));
		// return this.fetchEquipment().pipe(
		// 	tap((data) => this.cache$.get('equipment')?.next(data)),
		// 	switchMap(() => this.cache$.get('equipment')?.asObservable() as Observable<Equipment[]>),
		// );
	}

	fetchEquipment(): Observable<Equipment[]> {
		return this.fetchAgdirEquipment();
		// return forkJoin([this.fetchAgdirEquipment(), this.fetchJohnDeereEquipment()]).pipe(map((sources) => sources.flat()));
	}

	fetchAgdirEquipment(): Observable<Equipment[]> {
		return this.http.get<Equipment[]>(`${this.API_ENDPOINT}equipment`).pipe(first());
	}

	fetchJohnDeereEquipment(): Observable<Equipment[]> {
		return this.getIntegration(VendorName.JOHN_DEERE).pipe(
			filter((v) => !!v.connected),
			switchMap((v) => this.getMachines(String(v.remoteId))),
			first(),
		);
	}

	getMachines(organziationId: string): Observable<Equipment[]> {
		return this.http
			.get<{
				values: Array<JohnDeereMachine>;
			}>(`${this.API_ENDPOINT}vendors/{companyId}/organizations/${organziationId}/machines`)
			.pipe(map(({ values }) => values.map(JohnDeereMapper.toEquipment)));
	}

	getMachine(machineId: string | number): Observable<Equipment> {
		// TODO: Bljadj, nopietni?
		const isJohnDeere = String(machineId) === String(Number(machineId));
		return isJohnDeere ? this.getJohnDeereMachine(machineId) : this.getAgdirMachine(machineId);
	}

	getJohnDeereMachine(machineId: string | number): Observable<Equipment> {
		return this.http
			.get<JohnDeereMachine>(`${this.API_ENDPOINT}vendors/{companyId}/machines/${machineId}`)
			.pipe(map(JohnDeereMapper.toEquipment));
	}

	getAgdirMachine(machineId: string | number): Observable<Equipment> {
		return this.fetchAgdirEquipment().pipe(map((all) => all.find((m) => m.id === machineId) as unknown as Equipment));
	}

	connect(): Observable<string> {
		return this.http.post<string>(`${this.API_ENDPOINT}vendors/{companyId}`, {});
	}

	getIntegrations(): Observable<EquipmentVendor[]> {
		if (this.cache$.has('integrations')) {
			return this.cache$.get('integrations')?.asObservable() as Observable<EquipmentVendor[]>;
		}
		this.cache$.set('integrations', new ReplaySubject<EquipmentVendor[]>(1));
		return this.http.get<EquipmentVendor[]>(`${this.API_ENDPOINT}vendors/{companyId}`).pipe(
			tap((data) => this.cache$.get('integrations')?.next(data)),
			switchMap(() => this.cache$.get('integrations')?.asObservable() as Observable<EquipmentVendor[]>),
		);
	}

	getIntegration(vendor: VendorName): Observable<EquipmentVendor> {
		return this.getIntegrations().pipe(map((integrations) => integrations.find((v) => v.name === vendor) || ({} as EquipmentVendor)));
	}

	updateIntegration(vendor: { id: string; remoteId: string }) {
		return this.http.patch<string>(`${this.API_ENDPOINT}vendors/{companyId}`, vendor);
	}

	createEquipment(equipment: Equipment) {
		return this.http.post<Equipment>(`${this.API_ENDPOINT}equipment`, equipment).pipe(
			tap(async (stored) => {
				// const cached = await firstValueFrom(this.cache$.get('equipment') as Observable<Equipment[]>);
				// this.cache$.get('equipment')?.next([...cached, stored]);
			}),
		);
	}

	updateEquipment(equipment: Equipment): Observable<Equipment> {
		return this.http.patch<Equipment>(`${this.API_ENDPOINT}equipment`, equipment).pipe(
			tap(async () => {
				const cached = await firstValueFrom(this.cache$.get('equipment') as Observable<Equipment[]>);
				const cachedEntry = cached.find((c) => c.id === equipment.id);
				if (cachedEntry) {
					Object.assign(cachedEntry, equipment);
				}
				this.cache$.get('equipment')?.next([...cached]);
			}),
		);
	}

	deleteEquipment(equipment: Equipment): Observable<Equipment> {
		return this.http.delete<Equipment>(`${this.API_ENDPOINT}equipment`, equipment).pipe(
			tap(async () => {
				const cached = await firstValueFrom(this.cache$.get('equipment') as Observable<Equipment[]>);
				const cachedEntry = cached.find((c) => c.id === equipment.id);
				this.cache$.get('equipment')?.next(cached.filter((e) => e !== cachedEntry));
			}),
		);
	}
}
