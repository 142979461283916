import { AgdirFile } from '@agdir/domain';
import { AgdirFileWithProgress, CameraUploadComponent, FileListComponent, FileService, FileUploadComponent, HumanSizePipe } from '@agdir/fillagring';
import { BadgeComponent } from '@agdir/ui/badge';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, filter, first, Observable } from 'rxjs';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-attachments',
	template: `
		<div class="flex flex-col h-full gap-3">
			<div class="flex-1 flex items-center justify-center flex-col gap-2">
				<div class="flex items-center gap-1">
					<agdir-camera-upload (fileAdded)="addFilesToList($event)"></agdir-camera-upload>
					<agdir-file-upload (fileAdded)="addFilesToList($event)"></agdir-file-upload>
				</div>
				<div class="text-center flex flex-col gap-1 items-center justify-center">
					@if (label() && size() == 'normal') {
						<div>{{ label() }}</div>
					}
					@if (label() && size() == 'large') {
						<div class="text-3xl md:text-4xl font-bold">{{ label() }}</div>
					}
					@if (description()) {
						<div class="flex-1 text-gray-500 leading-5">{{ description() }}</div>
					}
				</div>
			</div>
			<div class="flex flex-col">
				<agdir-file-list
					#fileList
					[files]="(filesList | async) || []"
					(filesChange)="filesChanged($event)"
					class="block w-full"
				></agdir-file-list>
				@if (filesInProgress$ | async; as filesInProgress) {
					@if (filesInProgress.length) {
						@for (file of filesInProgress$ | async; track $index) {
							<div class="w-full flex flex-row">
								<div class="flex-1 pl-2">
									<div>{{ file.name }}</div>
									<div class="text-sm text-gray-500">{{ file.size | humanSize }} ({{ file.type }})</div>
								</div>
								<agdir-badge class="ml-2">{{ file.progress | async | number: '1.0-0' }}%</agdir-badge>
							</div>
						}
					}
				}
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
				height: 100%;
			}
		`,
	],
	imports: [CameraUploadComponent, FileUploadComponent, FileListComponent, AsyncPipe, HumanSizePipe, DecimalPipe, BadgeComponent],
})
export class AttachmentsComponent {
	control = input.required<FormControl<AgdirFile[]>>();
	folder = input.required<string>();
	label = input<string>();
	translate = input<'false' | 'true' | boolean>(true);
	size = input<string>('normal');
	description = input<string>('');
	horizontal = input<'false' | 'true' | boolean>();
	placeholder = input<string>();
	filesInProgress$ = new BehaviorSubject<AgdirFileWithProgress[]>([]);
	fileService = inject(FileService);
	filesUpdated = output<AgdirFile[]>();

	filesList?: Observable<AgdirFile[]>;

	ngOnInit() {
		this.filesList = this.control().valueChanges;
	}

	filesChanged(files: AgdirFile[]) {
		this.control().setValue(files);
		this.filesUpdated.emit(files);
	}

	async addFilesToList(file: File) {
		const folder = this.folder();
		const x = await this.fileService.upload(file, folder);
		this.filesInProgress$.next([...this.filesInProgress$.value, x]);
		x.done
			.pipe(
				filter((done) => !!done),
				first(),
			)
			.subscribe(() => {
				this.filesInProgress$.next(this.filesInProgress$.value.filter((f) => f !== x));
				this.control().setValue([...(this.control().value || []), x.toObject()]);
				this.filesUpdated.emit([...(this.control().value || []), x.toObject()]);
			});
	}
}
