import { AgdirDatatableComponent, AgdirDatatableEntity } from '@agdir/datatable';
import { Share } from '@agdir/domain';
import { SharesService } from '@agdir/services';
import { ButtonComponent } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, inject, input, model, output, signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzFormControlComponent, NzFormItemComponent, NzFormLabelComponent, NzFormModule } from 'ng-zorro-antd/form';
import { NzModalContentDirective, NzModalModule } from 'ng-zorro-antd/modal';
import { derivedAsync } from 'ngxtension/derived-async';
import { firstValueFrom } from 'rxjs';

@Component({
	selector: 'agdir-people',
	template: `
		<nz-form-item>
			<nz-form-control>
				@if (label() && size() == 'normal') {
					<nz-form-label>{{ translate() ? (label() | transloco) : label() }}</nz-form-label>
				}
				@if (label() && size() == 'large') {
					<div class="text-3xl md:text-4xl font-bold">{{ translate() ? (label() | transloco) : label() }}</div>
				}
				@if (description() && size() == 'large') {
					<div class="text-xl flex-1 font-light mb-5">{{ translate() ? (description() | transloco) : description() }}</div>
				}
				<nz-modal [nzVisible]="open()" [nzClosable]="false">
					<ng-container *nzModalContent>
						<agdir-datatable [data]="items()" (toggleSelection)="personSelected($event)" [nameLabel]="label() | transloco" />
					</ng-container>
					<ng-container *nzModalFooter>
						<agdir-button color="ghost" label="general.closeText" (click)="open.set(false)" />
						<agdir-button color="green" size="large" (click)="closeAndEmit()" label="general.saveText" />
					</ng-container>
				</nz-modal>
			</nz-form-control>
		</nz-form-item>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NzFormItemComponent,
		NzFormControlComponent,
		NzFormLabelComponent,
		TranslocoPipe,
		AgdirDatatableComponent,
		NzModalContentDirective,
		ButtonComponent,
		NzModalModule,
		NzFormModule,
	],
})
export class PeopleComponent {
	share$ = inject(SharesService).getCurrentCompanyCustomers();
	items = derivedAsync<AgdirDatatableEntity<Share>[]>(async () => {
		const selectedPeopleCustomerIds = this.selectedCustomerIds();
		const shares = await firstValueFrom(this.share$);
		return shares.map<AgdirDatatableEntity<Share>>((share) => ({
			id: Math.random() * 1000, // mongodb objectids do not convert to ids
			selected: selectedPeopleCustomerIds.includes(share.customerId),
			name: `${share.customer?.name} ${share.customer?.surname} (${share.customer?.phoneNumber})`,
			model: share,
			expand: false,
		}));
	});
	selectedCustomerIds = model<string[]>([]);
	label = input<string>();
	translate = input<'false' | 'true' | boolean>(true);
	size = input<string>();
	description = input<string>('');
	horizontal = input<'false' | 'true' | boolean>();
	placeholder = input<string>();

	selectionChanged = output<Share[]>();
	open = signal<boolean>(false);

	personSelected($event: AgdirDatatableEntity) {
		this.selectedCustomerIds.update((ids) => {
			if ($event.selected) {
				ids.push($event.model!.customerId);
			} else {
				ids.splice(ids.indexOf($event.model!.customerId), 1);
			}
			return ids;
		});
	}

	async closeAndEmit() {
		this.open.set(false);
		const allShares = await firstValueFrom(this.share$);
		this.selectionChanged.emit(allShares.filter((share) => this.selectedCustomerIds().includes(share.customerId)));
	}
}
