import { Component, model } from '@angular/core';
import { Equipment } from '@agdir/vogn/domain';
import { ButtonComponent } from '@agdir/ui/button';
import { NzTableModule } from 'ng-zorro-antd/table';


@Component({
	selector: 'agdir-equipment-selected-list',
	template: `
		<nz-table
			#basicTable
			nzSize="small"
			[nzData]="list()"
			nzBordered
			nzShowSizeChanger
			[nzFrontPagination]="true"
			[nzPageSize]="20"
			[nzPageSizeOptions]="[10, 20, 30, 50, 100]"
		>
			<tbody>
				@for (item of basicTable.data; track $index) {
					<tr>
						<td>{{ item.name }}</td>
						<td class="w-[30px]">
							<agdir-button color="red" (click)="remove(item)" label="general.unassignText" />
						</td>
					</tr>
				}
			</tbody>
		</nz-table>
	`,
	imports: [ButtonComponent, NzTableModule],
})
export class EquipmentSelectedListComponent {
	list = model<Equipment[]>([]);

	async remove(itemToRemove: Equipment): Promise<void> {
		this.list.update((list) => list.filter((item) => +item.id !== +itemToRemove.id));
	}
}
