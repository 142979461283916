import { CompanyAssetsService } from '@agdir/services';
import { inject, Injectable, signal } from '@angular/core';
import { Equipment } from '@agdir/vogn/domain';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EquipmentService {
	getEquipmentList = signal<Equipment[]>([]);
	private API_ENDPOINT = '/{companyId}/';
	private http = inject(CompanyAssetsService);

	constructor() {
		this.loadEquipmentList();
	}

	loadEquipmentList() {
		firstValueFrom(this.http.get<Equipment[]>(`${this.API_ENDPOINT}equipment`)).then((e) => this.getEquipmentList.set(e));
	}

	getEquipment(id: number) {
		return this.http.get<Equipment>(`${this.API_ENDPOINT}equipment/${id}`);
	}

	updateEquipment(equipment: Equipment) {
		return this.http.patch<Equipment>(`${this.API_ENDPOINT}equipment/${equipment.id}`, equipment);
	}

	deleteEquipment(equipment: Equipment) {
		return this.http.delete<Equipment>(`${this.API_ENDPOINT}equipment/${equipment.id}`);
	}

	createEquipment(equipment: Equipment) {
		return this.http.post<Equipment>(`${this.API_ENDPOINT}equipment`, equipment);
	}

	reload() {
		this.loadEquipmentList();
	}
}
