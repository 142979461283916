import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { CropTypeSelectComponent } from '../components/crop-select.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-formly-crop-type',
	template: `
		<nz-form-item class="w-full">
			<nz-form-label>{{ props.label }}</nz-form-label>
			<agdir-crop-select [value]="formControl.value" (picked)="formControl.setValue($event.code)"></agdir-crop-select>
		</nz-form-item>
	`,
	styles: [
		`
			:host ::ng-deep nz-cascader.ant-cascader {
				width: 100%;
			}
		`,
	],
	imports: [ReactiveFormsModule, NzFormModule, CropTypeSelectComponent],
})
export class CropTypeComponent extends FieldType<FieldTypeConfig> {}
