import { Component, model, output, signal } from '@angular/core';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Classifier } from '../classifier';
import { ClassifierService } from '../classifier.service';
import { FormsModule } from '@angular/forms';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { ButtonComponent } from '@agdir/ui/button';

@Component({
	selector: 'agdir-add-classifier-modal',
	template: `
		<nz-modal [nzVisible]="true" nzTitle="Add new classifier" [nzContent]="content" [nzFooter]="footer" (nzOnCancel)="cancel.emit()">
			<ng-template #content>
				<label for="classifierName">Classifier Name:</label>
				<input nz-input id="classifierName" name="classifierName" [(ngModel)]="classifierName" required />
			</ng-template>
			<ng-template #footer>
				<agdir-button (click)="cancel.emit()" label="general.cancelText" />
				<agdir-button color="green" [isProcessing]="isLoading()" (click)="addClassifier()" label="general.ok" />
			</ng-template>
		</nz-modal>
	`,
	imports: [NzModalModule, FormsModule, NzInputDirective, ButtonComponent],
})
export class AddClassifierModalComponent {
	classifierName = model<string>('');
	parent = model<Classifier | null>(null);
	isLoading = signal<boolean>(false);
	cancel = output<void>();
	classifierAdded = output<Classifier>();

	constructor(
		private message: NzMessageService,
		private classifierService: ClassifierService,
	) {}

	async addClassifier(): Promise<void> {
		if (!this.classifierName) {
			return; // Handle empty input case
		}
		this.isLoading.set(true);
		const theCreated = await this.classifierService.addCustom({
			name: this.classifierName(),
			code: this.classifierName(),
			parentId: this.parent()?.id,
		});
		this.parent()?.children?.push(theCreated);
		this.isLoading.set(false);
		this.message.success('Your new classifier is saved');
		this.classifierAdded.emit(theCreated);
	}
}
