import { I18nService } from '@agdir/i18n/angular';
import { AgdirConfirmationService } from '@agdir/ui';
import { Component, EventEmitter, input, NgZone, output } from '@angular/core';
import { Point } from 'geojson';

@Component({
	selector: 'agdir-find-me',
	template: `
		<button (click)="findMe()" class="flex flex-col items-center justify-center w-full h-auto py-1 px-0.5" type="button">
			<agdir-icon [class.animate-spin]="locating$ | async" [class.text-red-500]="locateError$ | async" class="w-3" icon="my_location" />
			<span>Find Me</span>
		</button>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
	standalone: false,
})
export class FindMeComponent {
	myPosition = output<Point>();
	textLabel = input('');

	locating$ = new EventEmitter<boolean>(false);
	locateError$ = new EventEmitter<boolean>(false);

	myLocationSearchIcon = 'my_location';

	constructor(
		private agdirConfirmationService: AgdirConfirmationService,
		private i18nService: I18nService,
		private window: Window,
		private zone: NgZone,
	) {}

	findMe() {
		this.locating$.emit(true);
		this.locateError$.emit(false);

		this.window.navigator.geolocation.getCurrentPosition(
			(position) => {
				this.zone.run(() => {
					this.myPosition.emit({
						type: 'Point',
						coordinates: [position.coords.longitude, position.coords.latitude],
					});
					this.locating$.emit(false);
				});
			},
			(e) => {
				this.zone.run(() => {
					this.processGeoPositionError(e);
				});
			},
			{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 },
		);
	}

	processGeoPositionError(e: GeolocationPositionError): void {
		this.locating$.emit(false);
		this.locateError$.emit(true);

		if (e.code === 1) {
			this.myLocationSearchIcon = 'location_disabled';
			this.agdirConfirmationService.open({
				title: this.i18nService.translate('modules.onboarding.selectFieldPage.permissionDenied.title'),
				message: this.i18nService.translate('modules.onboarding.selectFieldPage.permissionDenied.text'),
				icon: {
					show: true,
					name: 'location_disabled',
				},
				actions: {
					confirm: {
						show: true,
						label: this.i18nService.translate('modules.onboarding.selectFieldPage.permissionDenied.okButton'),
					},
					cancel: {
						show: false,
					},
				},
			});
		} else if (e.code === 2) {
			this.myLocationSearchIcon = 'location_disabled';
			this.agdirConfirmationService.open({
				title: this.i18nService.translate('modules.onboarding.selectFieldPage.positionUnavailable.title'),
				message: this.i18nService.translate('modules.onboarding.selectFieldPage.positionUnavailable.text'),
				icon: {
					show: true,
					name: 'location_disabled',
				},
				actions: {
					confirm: {
						show: true,
						label: this.i18nService.translate('modules.onboarding.selectFieldPage.positionUnavailable.okButton'),
					},
					cancel: {
						show: false,
					},
				},
			});
		}
	}
}
